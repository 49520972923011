<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Lote: ${batch.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row class="mt-2">
          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Estado</p>
                <span class="text--primary text-xl font-weight-bold" v-if="batch.status">
                  {{ batch.status ? batch.status.label : '-' }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
                <div class="d-flex">
                  <v-avatar size="44" color="warning" rounded class="elevation-1">
                    <v-icon dark color="white" size="30">
                      {{ icons.mdiCalendarQuestion }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="text-s mb-0">Fecha de creación</p>
                    <span class="text--primary text-xl font-weight-bold">
                      {{ batch.created_at }}
                    </span>
                  </div>
                </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiAccountMultiplePlus }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Contactos procesados</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ `${batch.processed_phone_number} de ${batch.total_phone_number}` }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="accent"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                    {{icons.mdiCellphoneWireless}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Canales asociados
                </span>
                <p v-if="!batch.channels || !batch.channels.length" class="text-s mb-0">No hay canales asociados.</p>
                <div style="overflow-y:auto;max-height: 105px;">
                  <div v-for="(item, idx) in batch.channels" :key="`channel_item_${idx}`">
                    <p class="text-s mb-0">{{item.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="secondary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalendarQuestion }}
                </v-icon>
              </v-avatar>

              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Fecha de inicio y horarios
                </span>

                <p class="text-s mb-0">
                  Fecha de inicio: {{ batch.start_date }}
                </p>
                <p class="text-s mb-0" @click="datetime_dialog = true">
                  <a>Ver días y horarios</a>
                </p>
                <span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

        <template>
          <v-dialog v-model="datetime_dialog" width="250">
            <v-card class="text-center">
              <v-card-title class="primary--text text-h5 d-block">
                Días y horarios
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-for="item in batch.datetime_info">
                {{ parseDays(item.day) }}: {{ item.start_hour.split(":", 2).join(":") }} a
                {{ item.end_hour.split(":", 2).join(":") }}
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary " text @click="datetime_dialog = false">
                  CERRAR
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

      <ContactsList
      ref="contact_list"
      :parent-element="'filter-batches'"
      :filter-batch="batch"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import AlertPopUp from "../AlertPopUp.vue";
import ContactsList from "../Contacts/ContactsList.vue";
import {
  mdiClose,
  mdiHelpCircleOutline,
  mdiCalendarQuestion,
  mdiAccountMultiplePlus,
  mdiCellphoneWireless
} from "@mdi/js";

export default {
  data:() => {
    return {
      loading: false,
      dialog: false,
      datetime_dialog: false,
    }
  },
  methods: {
    async getFilterBatch(id) {
      this.loading = true;
      try {
        const response = await this.$api.getFilterBatch(id);
        this.batch = response.data
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    open(item) {
      this.getFilterBatch(item.id)
      this.dialog = true;
    },
    close() {
      this.batch = {};
      this.dialog = false;
    },
    parseDays(day) {
      const weekDays = [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ];
      return weekDays[day];
    },
  },
  setup() {
    const batch = ref({})

    return {
      batch,
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiCalendarQuestion,
        mdiAccountMultiplePlus,
        mdiCellphoneWireless
      }
    }
  },
  components: { AlertPopUp, ContactsList },
}
</script>