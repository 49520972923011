import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"contact-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3 px-2 no-uppercase",attrs:{"color":"warning"},on:{"click":function($event){return _vm.$refs.import_filter_batch_modal.open()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiFileUploadOutline))]),_c('span',[_vm._v("Importar")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.batchesListTable,"page":_vm.current_page,"server-items-length":_vm.totalBatchesListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingBatchesPage($event)},"update:items-per-page":function($event){return _vm.changingBatchesItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.filter_batch_detail.open(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.channels),function(channel,idx){return _c('div',{staticClass:"d-flex align-end mb-1"},[_c('span',{staticClass:"text-left",class:!channel.status ? 'error--text' : ''},[_vm._v(_vm._s(channel.name)+": "+_vm._s(channel.filtered_contacts))]),(channel.in_breakdown)?_c(VTooltip,{attrs:{"right":"","color":"primary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"ml-2"},'button',attrs,false),on),[_c(VIcon,{staticStyle:{"width":"20px","height":"20px"},attrs:{"color":'primary'}},[_vm._v(" "+_vm._s(_vm.icons.mdiSleep)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descansando desde "+_vm._s((channel.filtering_block && channel.filtering_block.start_break_date) ? channel.filtering_block.start_break_date.replace('T', ' ').replace('Z', '') : '')+" hasta "+_vm._s((channel.filtering_block && channel.filtering_block.end_break_date) ? channel.filtering_block.end_break_date.replace('T', ' ').replace('Z', '') : ''))])]):_vm._e()],1)}),0)]}},{key:"item.total_phone_number",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{class:item.processed_phone_number === item.total_phone_number ? 'font-weight-bold': ''},[_vm._v(_vm._s(item.processed_phone_number))]),_c('span',[_vm._v(_vm._s(' de '))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_phone_number))])])]}},{key:"item.filtered_phone_number",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{class:item.filtered_phone_number === item.total_phone_number ? 'font-weight-bold': ''},[_vm._v(_vm._s(item.filtered_phone_number))]),_c('span',[_vm._v(_vm._s(' de '))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_phone_number))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(
              item.status.id !== 1 ||
              item.creation_progress_percentage === 100
            )?_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"depressed":"","color":_vm.statusColorParser(item.status.id),"max-height":"26px","min-width":"0"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.status.label === 'Pausado' ? 'Filtrando' : item.status.label))])]):_c('div',{staticClass:"rounded-lg relative",staticStyle:{"width":"100%","background-color":"#bdbdbd","height":"26px","position":"relative"}},[_c('div',{staticClass:"rounded-lg",style:({
                width: item.creation_progress_percentage + '%',
                'background-color':
                  item.creation_progress_percentage < 25
                    ? '#F44336'
                    : item.creation_progress_percentage >= 25 &&
                      item.creation_progress_percentage <= 50
                    ? '#FF9800'
                    : item.creation_progress_percentage > 50 &&
                      item.creation_progress_percentage <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              })}),_c('span',{staticStyle:{"position":"absolute","top":"2px","bottom":"0","left":"0","right":"0","color":"white"}},[_vm._v(_vm._s(item.creation_progress_percentage)+"%")])])]}},{key:"item.delete",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_batch_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}},{key:"item.update_status",fn:function(ref){
              var item = ref.item;
return [_c(VItemGroup,{staticClass:"v-btn-group"},[_c(VBtn,{staticClass:"grouped-btn px-3",attrs:{"depressed":"","color":"primary","min-width":"0","disabled":item.status.id !== 5},on:{"click":function($event){return _vm.$refs.update_status_filter_modal.open(item, 'reset')}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiReload)+" ")])],1),_c(VBtn,{staticClass:"grouped-btn px-3",attrs:{"depressed":"","color":"error","min-width":"0","disabled":item.status.id === 4 || item.status.id === 5},on:{"click":function($event){return _vm.$refs.update_status_filter_modal.open(item, 'pause')}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPause)+" ")])],1),_c(VBtn,{staticClass:"grouped-btn px-3",attrs:{"depressed":"","color":"success","min-width":"0","disabled":item.status.id !== 4},on:{"click":function($event){return _vm.$refs.update_status_filter_modal.open(item, 'resume')}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlay)+" ")])],1)],1)]}},{key:"item.reset",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","disabled":item.status.id !== 2},on:{"click":function($event){return _vm.$refs.reset_filter_modal.open(item)}}},[_c(VIcon,{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiReload)+" ")])],1)]}},{key:"item.pause",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","disabled":item.status.id === 0 || item.status.id === 1 || item.status.id === 3},on:{"click":function($event){return _vm.$refs.pause_filter_modal.open(item)}}},[_c(VIcon,{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiPauseCircleOutline)+" ")])],1)]}}],null,true)})],1),_c('ImportFilterBatches',{ref:"import_filter_batch_modal",on:{"reload-batches-list":function($event){return _vm.getFilterBatches()},"display-alert":_vm.showCreationAlert}}),_c('FilterBatchDetail',{ref:"filter_batch_detail"}),_c('DeleteFilterBatch',{ref:"delete_batch_modal",on:{"reload-batch-list":function($event){return _vm.getFilterBatches()},"display-alert":_vm.showCreationAlert}}),_c('UpdateStatusFilterBatch',{ref:"update_status_filter_modal",on:{"reload-batch-list":function($event){return _vm.getFilterBatches()},"display-alert":_vm.showCreationAlert}}),_c('alert-pop-up',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }