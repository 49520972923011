<template>
  <v-dialog
      v-model="showingPauseModal"
      width="auto"
  >
      <v-card>
          <v-card-title class="text-h5 grey lighten-2">
              ¿Estás seguro de {{ btn_action === 'reset' ? 'reiniciar' : btn_action === 'pause' ? 'pausar' : 'continuar' }} el filtrado del lote '{{ batch.name }}'?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
              color="primary"
              text
              @click="close()"
              >
                  CANCELAR
              </v-btn>
              <v-btn
              color="info"
              @click="btn_action === 'pause' ? pauseBatch() : resumeBatch()"
              >
                  {{btn_action === 'pause' ? 'Pausar' : btn_action === 'reset' ? 'Reiniciar' : 'Continuar'}}
              </v-btn>
          </v-card-actions>
          <alert-pop-up ref="alert_pop_up" />
      </v-card>
  </v-dialog>
</template>

<script>
import AlertPopUp  from '@/components/AlertPopUp.vue';

export default {
  data: () => {
      return {
          showingPauseModal: false,
          batch: {},
          btn_action: null
      }
  },
  components: {
      AlertPopUp
  },

  methods: {
      open(batch, action) {
          this.btn_action = action
          this.showingPauseModal = true
          this.batch = batch
      },

      close() {
          this.btn_action = null
          this.showingPauseModal = false
      },

      async pauseBatch() {
          try {
            let form = {
              status: 4
            }
            const response = await this.$api.updateFilterBatchStatus(this.batch.id, form)

            this.$emit("display-alert", { message: 'El filtrado se pausó correctamente', type: 'success' });
            this.$emit('reload-batch-list')
            this.close()
          } catch(e) {
              console.log(e)
              if(e?.response?.status === 403) {
                  this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
              }
          }
      },

      // async resetBatch() {
      //     try {
      //         const response = await this.$api.resetBatchFeedDatabase(this.batch.id)

      //         this.$emit("display-alert", { message: 'El lote se reinició correctamente', type: 'success' });
      //         this.$emit('reload-batch-list')
      //         this.close()
      //     } catch(e) {
      //         console.log(e)
      //         if(e?.response?.status === 403) {
      //             this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
      //         }
      //     }
      // },

      async resumeBatch() {
          try {
            let form = {
              status: 3
            }
            const response = await this.$api.updateFilterBatchStatus(this.batch.id, form)

            this.$emit("display-alert", { message: this.btn_action === 'reset' ? 'El lote re reinició correctamente' : 'El filtrado se reanudó correctamente', type: 'success' });
            this.$emit('reload-batch-list')
            this.close()
          } catch(e) {
              console.log(e)
              if(e?.response?.status === 403) {
                  this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
              }
          }
      },
  }
}
</script>