<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Lote: ${batch.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row class="mt-2">
          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Estado</p>
                <span class="text--primary text-xl font-weight-bold" v-if="batch.status">
                  {{ batch.status ? batch.status.label : '-' }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
                <div class="d-flex">
                  <v-avatar size="44" color="warning" rounded class="elevation-1">
                    <v-icon dark color="white" size="30">
                      {{ icons.mdiCalendarQuestion }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="text-s mb-0">Fecha de creación</p>
                    <span class="text--primary text-xl font-weight-bold">
                      {{ batch.created_at }}
                    </span>
                  </div>
                </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiAccountMultiplePlus }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Contactos procesados</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ batch.total_phone_number }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <ContactsList
      ref="contact_list"
      :parent-element="'feed-database'"
      :feed-database="batch"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import AlertPopUp from "../AlertPopUp.vue";
import ContactsList from "../Contacts/ContactsList.vue";
import {
  mdiClose,
  mdiHelpCircleOutline,
  mdiCalendarQuestion,
  mdiAccountMultiplePlus,
  mdiCellphoneWireless
} from "@mdi/js";

export default {
  data:() => {
    return {
      loading: false,
      dialog: false,
    }
  },
  methods: {
    async getBatchFeedDatabase(id) {
      this.loading = true;
      try {
        const response = await this.$api.getBatchFeedDatabase(id);
        this.batch = response.data
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    open(item) {
      this.getBatchFeedDatabase(item.id)
      this.dialog = true;
    },
    close() {
      this.batch = {};
      this.dialog = false;
    },
  },
  setup() {
    const batch = ref({})

    return {
      batch,
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiCalendarQuestion,
        mdiAccountMultiplePlus,
        mdiCellphoneWireless
      }
    }
  },
  components: { AlertPopUp, ContactsList },
}
</script>