<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
        v-model="searchQuery"
        placeholder="Buscar por nombre"
        outlined
        hide-details
        dense
        class="contact-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
          color="warning"
          class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.import_feed_database_modal.open()"
          >
            <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
            <span>Importar</span>
          </v-btn>

        </div>
      </v-card-text>

      <v-data-table
      :headers="tableColumns"
      :items="batchesListTable"
      :page="current_page"
      :server-items-length="totalBatchesListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingBatchesPage($event)"
      @update:items-per-page="changingBatchesItemsPerPage($event)"
      >

        <!-- name -->
        <template #[`item.name`]="{item}">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.batch_feed_database_detail.open(item)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status.id)"
            max-height="26px"
            min-width="0"
            
          >
            <span style="color: white">{{ item.status.label }}</span>
          </v-btn>
          <!-- <div
            v-else
            class="rounded-lg relative"
            style="
              width: 100%;
              background-color: #bdbdbd;
              height: 26px;
              position: relative;
            "
          >
            <div
              class="rounded-lg"
              :style="{
                width: item.filtering_progress_percentage + '%',
                'background-color':
                  item.filtering_progress_percentage < 25
                    ? '#F44336'
                    : item.filtering_progress_percentage >= 25 &&
                      item.filtering_progress_percentage <= 50
                    ? '#FF9800'
                    : item.filtering_progress_percentage > 50 &&
                      item.filtering_progress_percentage <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              }"
            ></div>
            <span
              style="
                position: absolute;
                top: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
              "
              >{{ item.filtering_progress_percentage }}%</span
            >
          </div> -->
        </template>

        <template #[`item.delete`]="{item}">
					<v-btn
						icon
            @click="$refs.delete_batch_modal.open(item)"
					>
						<v-icon class="error--text">
							{{ icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
        </template>

        <template #[`item.reset`]="{item}">
					<v-btn
						icon
            @click="$refs.reset_batch_modal.open(item)"
            :disabled="item.status.id !== 2"
					>
						<v-icon class="primary--text">
							{{ icons.mdiReload }}
						</v-icon>
					</v-btn>
        </template>

      </v-data-table>
    </v-card>

    <ImportBatchFeedDatabase
    ref="import_feed_database_modal"
    @reload-batches-list="getBatchFeedDatabaseList()"
    @display-alert="showCreationAlert"
    />

    <BatchFeedDatabaseDetail
    ref="batch_feed_database_detail"
    />

    <DeleteBatchFeedDatabase
    ref="delete_batch_modal"
    @reload-batch-list="getBatchFeedDatabaseList()"
    @display-alert="showCreationAlert"
    />

    <ResetBatchFeedDatabase
    ref="reset_batch_modal"
    @reload-batch-list="getBatchFeedDatabaseList()"
    @display-alert="showCreationAlert"
    />

    <alert-pop-up ref="alert_pop_up" />

  </div>
</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import AlertPopUp from '@/components/AlertPopUp.vue';
import ImportBatchFeedDatabase from './ImportBatchFeedDatabase.vue';
import BatchFeedDatabaseDetail from './BatchFeedDatabaseDetail.vue'
import DeleteBatchFeedDatabase from './DeleteBatchFeedDatabase.vue'
import ResetBatchFeedDatabase from './ResetBatchFeedDatabase.vue'

import {
  mdiFileUploadOutline,
  mdiDeleteOutline,
  mdiReload,
  mdiPencilOutline
} from '@mdi/js'

export default {
  components: {
    AlertPopUp,
    ImportBatchFeedDatabase,
    BatchFeedDatabaseDetail,
    DeleteBatchFeedDatabase,
    ResetBatchFeedDatabase,
  },
  data:() => {
    return {
      searchQuery: '',
      current_page: 1,
      page_size: 10,
    }
  },
  methods: {
    async getBatchFeedDatabaseList() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany !== 'all' ? this.getSelectedCompany : '',
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getBatchFeedDatabaseList(query)

        this.batchesListTable = response.results
        this.totalBatchesListTable = response.count

      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    statusColorParser(status) {
      if (status === 0) return 'primary';
      if (status === 1) return 'info';
      if (status === 2) return 'success'
    },

    showCreationAlert(data) {
      this.$refs.alert_pop_up.showPopUp({ message: data.message, type: data.type })
    },

    changingBatchesPage(e) {
      this.current_page = e
      this.getBatchFeedDatabaseList()
    },

    changingBatchesItemsPerPage(e) {
      this.page_size = e
      this.getBatchFeedDatabaseList()
    },
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getBatchFeedDatabaseList()
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getBatchFeedDatabaseList()
      }
    }
  },
  setup() {
    const totalBatchesListTable = ref(1);
    const batchesListTable = ref([]);
    const loading = ref(false);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Fecha', value: 'created_at', align: 'center', sortable: false },
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
      { text: 'Reiniciar filtrado', value: 'reset', align: 'center', sortable: false },
    ];

    return {
      tableColumns,
      totalBatchesListTable,
      batchesListTable,
      loading,
      icons: {
        mdiFileUploadOutline,
        mdiDeleteOutline,
        mdiReload,
        mdiPencilOutline
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-uppercase {
  text-transform: unset !important;
}

.tag-container {
  color: white;
  background-color: rgb(120, 120, 120);
  font-size: 14px;
}

.tag-container:hover {
  cursor: default;
}
</style>