<template>
  <div>
      <v-card class="mb-6">
        <v-row>
          <v-card-title>Lotes de filtrado</v-card-title>
        </v-row>
        <v-tabs v-model="currentTab">
          <v-tab :value="0">Lotes de filtrado</v-tab>
          <v-tab :value="1">Alimentar base de datos</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <v-tab-item :value="0">
            <FilterBatchesList></FilterBatchesList>
          </v-tab-item>

          <v-tab-item :value="1">
              <FeedDatabaseList></FeedDatabaseList>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
  </div>
</template>

<script>
import FilterBatchesList from '@/components/FilterBatches/FilterBatchesList.vue'
import FeedDatabaseList from '@/components/FilterBatches/FeedDatabaseList.vue'

export default {
  components: {
    FilterBatchesList,FeedDatabaseList
  },
  data:() => {
    return {
      currentTab: 0
    }
  }
}
</script>
