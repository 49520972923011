import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mb-6"},[_c(VRow,[_c(VCardTitle,[_vm._v("Lotes de filtrado")])],1),_c(VTabs,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,{attrs:{"value":0}},[_vm._v("Lotes de filtrado")]),_c(VTab,{attrs:{"value":1}},[_vm._v("Alimentar base de datos")])],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,{attrs:{"value":0}},[_c('FilterBatchesList')],1),_c(VTabItem,{attrs:{"value":1}},[_c('FeedDatabaseList')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }