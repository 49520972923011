import { render, staticRenderFns } from "./ImportBatchFeedDatabase.vue?vue&type=template&id=1347d247&scoped=true&"
import script from "./ImportBatchFeedDatabase.vue?vue&type=script&lang=js&"
export * from "./ImportBatchFeedDatabase.vue?vue&type=script&lang=js&"
import style0 from "./ImportBatchFeedDatabase.vue?vue&type=style&index=0&id=1347d247&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1347d247",
  null
  
)

export default component.exports