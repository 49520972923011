<template>
  <v-dialog
      v-model="showingResetModal"
      width="auto"
  >
      <v-card>
          <v-card-title class="text-h5 grey lighten-2">
              ¿Estás seguro de reiniciar el lote '{{ batch.name }}'?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
              color="primary"
              text
              @click="close()"
              >
                  CANCELAR
              </v-btn>
              <v-btn
              color="info"
              @click="resetBatch()"
              >
                  Reiniciar
              </v-btn>
          </v-card-actions>
          <alert-pop-up ref="alert_pop_up" />
      </v-card>
  </v-dialog>
</template>

<script>
import AlertPopUp  from '@/components/AlertPopUp.vue';

export default {
  data: () => {
      return {
          showingResetModal: false,
          batch: {}
      }
  },
  components: {
      AlertPopUp
  },

  methods: {
      open(batch) {
          this.showingResetModal = true
          this.batch = batch
      },

      close() {
          this.showingResetModal = false
      },

      async resetBatch() {
          try {
              const response = await this.$api.resetBatchFeedDatabase(this.batch.id)

              this.$emit("display-alert", { message: 'El lote se reinició correctamente', type: 'success' });
              this.$emit('reload-batch-list')
              this.close()
          } catch(e) {
              console.log(e)
              if(e?.response?.status === 403) {
                  this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
              }
          }
      }
  }
}
</script>