<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
        v-model="searchQuery"
        placeholder="Buscar por nombre"
        outlined
        hide-details
        dense
        class="contact-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
          color="warning"
          class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.import_filter_batch_modal.open()"
          >
            <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
            <span>Importar</span>
          </v-btn>

        </div>
      </v-card-text>

      <v-data-table
      :headers="tableColumns"
      :items="batchesListTable"
      :page="current_page"
      :server-items-length="totalBatchesListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingBatchesPage($event)"
      @update:items-per-page="changingBatchesItemsPerPage($event)"
      >
    
      <!-- name -->
      <template #[`item.name`]="{item}">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.filter_batch_detail.open(item)"
          >
            {{ item.name }}
          </v-btn>
          <div class="d-flex flex-column">
            <div class="d-flex align-end mb-1" v-for="(channel, idx) in item.channels">
              <span class="text-left" :class="!channel.status ? 'error--text' : ''">{{ channel.name }}: {{ channel.filtered_contacts }}</span>
              <v-tooltip v-if="channel.in_breakdown" right color="primary" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <button
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2"
                  
                  >
                    <v-icon
                    :color="'primary'"
                    style="width: 20px; height: 20px;"
                    >
                      {{icons.mdiSleep}}
                    </v-icon>
                  </button>
                </template>
                <span>Descansando desde {{ (channel.filtering_block && channel.filtering_block.start_break_date) ? channel.filtering_block.start_break_date.replace('T', ' ').replace('Z', '') : ''}} hasta {{ (channel.filtering_block && channel.filtering_block.end_break_date) ? channel.filtering_block.end_break_date.replace('T', ' ').replace('Z', '') : '' }}</span>
              </v-tooltip>
            </div>
          </div>
        </template>

        <template #[`item.total_phone_number`]="{item}">
          <div>
            <span :class="item.processed_phone_number === item.total_phone_number ? 'font-weight-bold': ''">{{ item.processed_phone_number }}</span>
            <span>{{ ' de ' }}</span>
            <span class="font-weight-bold">{{ item.total_phone_number }}</span>
          </div>
        </template>

        <template #[`item.filtered_phone_number`]="{item}">
          <div>
            <span :class="item.filtered_phone_number === item.total_phone_number ? 'font-weight-bold': ''">{{ item.filtered_phone_number }}</span>
            <span>{{ ' de ' }}</span>
            <span class="font-weight-bold">{{ item.total_phone_number }}</span>
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status.id)"
            max-height="26px"
            min-width="0"
            v-if="
              item.status.id !== 1 ||
              item.creation_progress_percentage === 100
            "
          >
            <span style="color: white">{{ item.status.label === 'Pausado' ? 'Filtrando' : item.status.label  }}</span>
          </v-btn>
          <div
            v-else
            class="rounded-lg relative"
            style="
              width: 100%;
              background-color: #bdbdbd;
              height: 26px;
              position: relative;
            "
          >
            <div
              class="rounded-lg"
              :style="{
                width: item.creation_progress_percentage + '%',
                'background-color':
                  item.creation_progress_percentage < 25
                    ? '#F44336'
                    : item.creation_progress_percentage >= 25 &&
                      item.creation_progress_percentage <= 50
                    ? '#FF9800'
                    : item.creation_progress_percentage > 50 &&
                      item.creation_progress_percentage <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              }"
            ></div>
            <span
              style="
                position: absolute;
                top: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
              "
              >{{ item.creation_progress_percentage }}%</span
            >
          </div>
        </template>

        <template #[`item.delete`]="{item}">
					<v-btn
						icon
            @click="$refs.delete_batch_modal.open(item)"
					>
						<v-icon class="error--text">
							{{ icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
        </template>

        <template #[`item.update_status`]="{ item }">
          <v-item-group class="v-btn-group">
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="primary"
              min-width="0"
              @click="$refs.update_status_filter_modal.open(item, 'reset')"
              :disabled="item.status.id !== 5"
            >
              <v-icon>
                {{ icons.mdiReload }}
              </v-icon>
            </v-btn>
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="error"
              min-width="0"
              @click="$refs.update_status_filter_modal.open(item, 'pause')"
              :disabled="item.status.id === 4 || item.status.id === 5"
            >
              <v-icon>
                {{ icons.mdiPause }}
              </v-icon>
            </v-btn>
            <v-btn
              class="grouped-btn px-3"
              depressed
              color="success"
              min-width="0"
              @click="$refs.update_status_filter_modal.open(item, 'resume')"
              :disabled="item.status.id !== 4"
            >
              <v-icon>
                {{ icons.mdiPlay }}
              </v-icon>
            </v-btn>
          </v-item-group>
        </template>

        <template #[`item.reset`]="{item}">
					<v-btn
						icon
            @click="$refs.reset_filter_modal.open(item)"
            :disabled="item.status.id !== 2"
					>
						<v-icon class="primary--text">
							{{ icons.mdiReload }}
						</v-icon>
					</v-btn>
        </template>

        <template #[`item.pause`]="{item}">
					<v-btn
						icon
            @click="$refs.pause_filter_modal.open(item)"
            :disabled="item.status.id === 0 || item.status.id === 1 || item.status.id === 3"
					>
						<v-icon class="primary--text">
							{{ icons.mdiPauseCircleOutline }}
						</v-icon>
					</v-btn>
        </template>

      </v-data-table>
    </v-card>

    <ImportFilterBatches
    ref="import_filter_batch_modal"
    @reload-batches-list="getFilterBatches()"
    @display-alert="showCreationAlert"
    />

    <FilterBatchDetail
    ref="filter_batch_detail"
    />

    <DeleteFilterBatch
    ref="delete_batch_modal"
    @reload-batch-list="getFilterBatches()"
    @display-alert="showCreationAlert"
    />

    <UpdateStatusFilterBatch
    ref="update_status_filter_modal"
    @reload-batch-list="getFilterBatches()"
    @display-alert="showCreationAlert"
    />

    <alert-pop-up ref="alert_pop_up" />

  </div>
</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import AlertPopUp from '@/components/AlertPopUp.vue';
import ImportFilterBatches from './ImportFilterBatches.vue';
import FilterBatchDetail from './FilterBatchDetail.vue'
import DeleteFilterBatch from './DeleteFilterBatch.vue'
import UpdateStatusFilterBatch from './UpdateStatusFilterBatch.vue'

import {
  mdiFileUploadOutline,
  mdiDeleteOutline,
  mdiReload,
  mdiPencilOutline,
  mdiPause,
  mdiPlay,
  mdiSleep
} from '@mdi/js'

export default {
  components: {
    AlertPopUp,
    ImportFilterBatches,
    FilterBatchDetail,
    DeleteFilterBatch,
    UpdateStatusFilterBatch
  },
  data:() => {
    return {
      searchQuery: '',
      current_page: 1,
      page_size: 10,
      timer: null,
    }
  },
  methods: {
    async getFilterBatches() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany !== 'all' ? this.getSelectedCompany : '',
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getFilterBatches(query)

        this.batchesListTable = response.results
        this.totalBatchesListTable = response.count

      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
        this.setTimer()
      }
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.getFilterBatches()
      }, this.batchesListTable.some(el => el.status.id === 0 || el.status.id === 1 || el.status.id === 2 || el.status.id === 3) ? 10000 : 6000000 )
    },

    statusColorParser(status) {
      if (status === 0) return 'primary';
      if (status === 2) return 'info';
      if (status === 3) return 'info';
      if (status === 4) return 'error';
      if (status === 5) return 'success'
    },

    showCreationAlert(data) {
      this.$refs.alert_pop_up.showPopUp({ message: data.message, type: data.type })
    },

    changingBatchesPage(e) {
      this.current_page = e
      this.getFilterBatches()
    },

    changingBatchesItemsPerPage(e) {
      this.page_size = e
      this.getFilterBatches()
    },
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getFilterBatches()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getFilterBatches()
      }
    }
  },
  setup() {
    const totalBatchesListTable = ref(1);
    const batchesListTable = ref([]);
    const loading = ref(false);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Cantidad de teléfono', value: 'total_phone_number', align: 'center', sortable: false },
      { text: 'Filtrados', value: 'filtered_phone_number', align: 'center', sortable: false },
      { text: 'Fecha', value: 'created_at', align: 'center', sortable: false },
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
      { text: 'Cambiar estados', value: 'update_status', align: 'center', sortable: false },
    ];

    return {
      tableColumns,
      totalBatchesListTable,
      batchesListTable,
      loading,
      icons: {
        mdiFileUploadOutline,
        mdiDeleteOutline,
        mdiReload,
        mdiPencilOutline,
        mdiPause,
        mdiPlay,
        mdiSleep
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-uppercase {
  text-transform: unset !important;
}

.tag-container {
  color: white;
  background-color: rgb(120, 120, 120);
  font-size: 14px;
}

.tag-container:hover {
  cursor: default;
}

.grouped-btn {
  border: none;
  border-radius: 0px;
}

.grouped-btn:first-child {
  border-radius: 10px 0 0 10px;
}

.grouped-btn:last-child {
  border-radius: 0 10px 10px 0;
}
</style>